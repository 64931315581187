import { useState, useRef, ChangeEvent, KeyboardEvent, useContext, useEffect } from "react";
import Logo from "../../assets/Logo.png";
import Button from "../../components/Button";
import { AuthContext } from "../../contexts/authContext";
import OTPInput from "./OTPInput";

export default function MFA() {
  const auth = useContext(AuthContext);
  const [otp, setOtp] = useState<string>("");
  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [rememberDevice, setRememberDevice] = useState<boolean>(true);

  async function verifyOtp(otp: string) {
    setSubmitting(true);
    if (auth.verifyOTP) {
      try {
        await auth.verifyOTP(otp, rememberDevice);
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);
        }
      }
    }
    setSubmitting(false);
  }

  useEffect(() => {
    if (otp.length === 6) {
      verifyOtp(otp);
    }
  }, [otp]);

  return (
    <div className="h-screen bg-gray-50">
      <div className="min-h-full flex flex-col py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={Logo} alt="Strata Intel" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Two-factor authentication</h2>
          <p className="mt-2 text-center text-sm text-gray-600">Enter the six digit code from your authenticator app</p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded sm:px-10">
            <div className="space-y-6" id="loginForm">
              <OTPInput otp={otp} setOtp={setOtp} />
              <div>
                <div className="relative flex items-start justify-center">
                  <div className="flex h-6 items-center">
                    <input
                      id="rememberDevice"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-strataBlue focus:ring-strataBlue"
                      checked={rememberDevice}
                      onChange={(e) => setRememberDevice(e.target.checked)}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6 mb-2">
                    <label htmlFor="rememberDevice" className="font-medium text-gray-900">
                      Remember this device
                    </label>
                  </div>
                </div>
                <Button id="submitButton" type="submit" text="Verify" submitting={submitting} onClick={() => verifyOtp(otp)} className="w-full" />
              </div>
            </div>
            <div className="mt-3 text-sm text-red-500">{error}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
