import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { ParticipantUpload } from "../../types";
import { formatPhoneNumber } from "../../hooks/helpers";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

function range(length: number) {
  return Array.from({ length }, (_, i) => i + 1);
}

export default function UserTable(props: { users: ParticipantUpload[]; limit: number }) {
  const { users, limit } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);

  const totalPages = Math.ceil(users.length / limit);
  const pages = range(totalPages);

  function paginate(users: ParticipantUpload[], pageNumber: number) {
    const start = (pageNumber - 1) * limit;
    return users.slice(start, start + limit);
  }

  const currentPageUsers = paginate(users, currentPage);

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 overflow-x-scroll sm:overflow-y-hidden">
        <div className="flex flex-col mt-4">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="">
                {users.length < 5 && (
                  <div className="rounded-md bg-yellow-50 p-4 mb-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400" />
                      </div>
                      <div className="ml-3">
                        <div className="text-sm text-yellow-700">
                          To view the results for this group, please ensure there are at least 5 participants to maintain anonymity
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="border rounded-md">
                  <table className="min-w-full shadow-sm" style={{ borderSpacing: 0 }}>
                    <thead className="">
                      <tr className="z-10 border-b border-gray-300 h-10 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                        <th className="pl-2">First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Job Title</th>
                        <th>Groups</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {currentPageUsers.map((user, index: number) => (
                        <tr key={index} className="whitespace-nowrap h-10 pl-4 text-sm border-b border-gray-200">
                          <td className={"text-gray-900 pl-2"}>{user.first_name}</td>
                          <td className={"text-gray-900"}>{user.last_name}</td>
                          <td className={"text-gray-900"}>{user.email}</td>
                          <td className={"text-gray-900"}>{user.phone_number && formatPhoneNumber(user.phone_number)}</td>
                          <td className={"text-gray-500"}>{user.job_title}</td>
                          <td className={"text-gray-500"}>{user.group_names.join(", ")}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between py-3">
        <div className="flex flex-1 justify-between sm:hidden">
          {currentPage !== 1 && (
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-white"
              disabled={currentPage === 1}
            >
              Previous
            </button>
          )}
          {currentPage !== pages.length && (
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-white"
            >
              Next
            </button>
          )}
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            {users.length > 0 ? (
              <p className="text-sm text-gray-700 ml-1">
                Showing <span className="font-medium">{(currentPage - 1) * limit + 1}</span> to{" "}
                <span className="font-medium">{currentPage * limit > users.length ? users.length : currentPage * limit}</span> of{" "}
                <span className="font-medium">{users.length}</span> users
              </p>
            ) : (
              <p className="text-sm text-gray-700 ml-1">No Results</p>
            )}
          </div>
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className={`${
                  currentPage === 1 && "opacity-30"
                } relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-white focus:z-20`}
                disabled={currentPage === 1}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {pages.map((page) => {
                let show = false;
                let isEllipsis = false;
                if (pages.length <= 10) {
                  // show all pages if under 10
                  show = true;
                } else if (page === 1 || page === totalPages) {
                  // always show first and last page
                  show = true;
                } else if (page <= currentPage + 3 && page >= currentPage - 3) {
                  // show pages next to current
                  show = true;
                } else if (page === currentPage + 4 || page === currentPage - 4) {
                  // show ellipsis for number in between ranges
                  show = true;
                  isEllipsis = true;
                }

                if (show) {
                  return (
                    <button
                      key={page}
                      aria-current="page"
                      onClick={() => setCurrentPage(page)}
                      className={
                        page === currentPage
                          ? "relative z-10 inline-flex items-center border border-blue-700 bg-blue-50 px-4 py-2 text-sm font-medium text-blue-800 focus:z-20"
                          : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-white focus:z-20"
                      }
                      disabled={page === currentPage || isEllipsis}
                    >
                      {isEllipsis ? "..." : page}
                    </button>
                  );
                }
              })}
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className={`${
                  currentPage === pages.length && "opacity-30"
                } relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-white focus:z-20`}
                disabled={currentPage === pages.length}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
