// Mental Health Cut-Off Values
export const MID_RED_UPPER_CUTOFF = 88.88;
export const LOW_RED_UPPER_CUTOFF = 77.77;
export const HIGH_YELLOW_UPPER_CUTOFF = 66.66;
export const MID_YELLOW_UPPER_CUTOFF = 55.55;
export const LOW_YELLOW_UPPER_CUTOFF = 44.44;
export const HIGH_GREEN_UPPER_CUTOFF = 33.33;
export const MID_GREEN_UPPER_CUTOFF = 22.22;
export const DISENGAGED_UPPER_CUTOFF = 9;

// SI Value Cut-Off Values
export const SI_VALUE_UPPER_CUTOFF = 66.66;
export const SI_VALUE_LOWER_CUTOFF = 33.33;