import { useContext, useEffect, useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "../../contexts/authContext";
import QRCode from "qrcode.react";
import Button from "../../components/Button";
import OTPInput from "../Login/OTPInput";
import MFAOptions from "./MFAOptions";

export default function MFASetupModal(props: { open: boolean; setOpen: (open: boolean) => void }) {
  const { open, setOpen } = props;
  const auth = useContext(AuthContext);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [secretCode, setSecretCode] = useState("");
  const [otp, setOtp] = useState<string>("");
  const [step, setStep] = useState<number>(1);

  async function setupMFA() {
    const code = auth.setupMFA ? await auth.setupMFA() : null;
    setSecretCode(code ?? "");
  }

  useEffect(() => {
    if (open && !auth.mfaEnabled) {
      setupMFA();
    }
  }, [open, auth]);

  useEffect(() => {
    if (otp.length === 6) {
      confirmMFA();
    }
  }, [otp]);

  async function confirmMFA() {
    setSubmitting(true);
    if (!auth.confirmMFA) {
      return;
    }

    try {
      const result = await auth.confirmMFA(otp);
      if (result === "EnableSoftwareTokenMFAException") {
        setError("Incorrect code, please try again");
      }
      if (result === "Confirmed") {
        setMfaEnabled(true);
      }
    } catch (err: unknown) {
      console.error(err);
      throw err;
    }
    setSubmitting(false);
  }

  const username = auth.claims.email;

  // Generate the TOTP URI
  const totpUri = `otpauth://totp/StrataIntel:${username}?secret=${secretCode}&issuer=StrataIntel`;

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            {step == 1 ? (
              <div className="flex flex-col">
                <h2 className="text-xl text-center text-gray-800 font-medium mb-4">Select Security Method</h2>
                <MFAOptions />
                <p className="mt-4 text-xs text-center text-gray-600">Future options available soon</p>
                <Button text="Next" className="mt-4" onClick={() => setStep(2)} />
              </div>
            ) : step == 2 ? (
              <div className="flex flex-col">
                <h2 className="text-xl text-center text-gray-800 font-medium mb-4">Instructions for Setup</h2>

                <ul>
                  <li className="flex gap-x-4 py-5">
                    <h1>1.</h1>
                    <div className="min-w-0">
                      <p className="text-sm font-semibold leading-6 text-gray-900">Download an Authentication App</p>
                      <p className="mt-1 text-xs leading-5 text-gray-500">If you don't have one already, we recommend downloading the app "Authy" or "Okata Verify."</p>
                    </div>
                  </li>
                  <li className="flex gap-x-4 py-5">
                    <h1>2.</h1>
                    <div className="min-w-0">
                      <p className="text-sm font-semibold leading-6 text-gray-900">Scan QR code with your authentication app</p>
                      <p className="mt-1 text-xs leading-5 text-gray-500">Enter the 6 Digit Code after you scan the QR Code to finish MFA Setup.</p>
                    </div>
                  </li>
                </ul>


                <Button text="Scan QR Code" className="mt-4" onClick={() => setStep(3)} />
              </div>
            ) : (
              <div>
                {!mfaEnabled ? (
                  <div className="text-center">
                    <h2 className="text-xl text-gray-800 font-medium">Register two-factor authentication</h2>
                    <p className="text-sm text-gray-500">
                      Install a two-factor authentication app and scan this QR code to register.
                    </p>
                    <div className="flex justify-center mt-4 mb-4">
                      <QRCode value={totpUri} />
                    </div>
                    <label className="text-gray-500 text-sm">
                      Enter code from authentication app:
                      <OTPInput otp={otp} setOtp={setOtp} />
                    </label>
                    {error && <div className="text-sm text-red-500 mt-3">{error}</div>}
                    <Button text="Register" onClick={confirmMFA} className="w-full mt-5" />
                  </div>
                ) : (
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Success!
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Multi-factor authentication has been successfully enabled on your account
                        </p>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-6">
                      <Button
                        text="Back to dashboard"
                        type="button"
                        onClick={() => setOpen(false)}
                        className="w-full"
                        submitting={submitting}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
