import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { ParticipantColumn, ParticipantColumnMapping } from "../../types";

const propertyNames = {
  first_name: "First Name",
  last_name: "Last Name",
  email: "Email",
  phone_number: "Phone Number",
  job_title: "Job Title",
  group_names: "Group",
} as const;

// case insensitive
// will match if any part of the column name matches any mapping
// ex. "first" matches with "First Name"
const defaultMappings = {
  first_name: ["first", "given"],
  last_name: ["last", "family"],
  email: ["email"],
  phone_number: ["phone"],
  job_title: ["title", "position"],
  group_names: ["group"],
} as const;

export default function ColumnMapper(props: {
  data: string[][];
  columnMappings: ParticipantColumnMapping[];
  setColumnMappings: (value: ParticipantColumnMapping[]) => void;
}) {
  const { data, columnMappings, setColumnMappings } = props;
  const columns = data[0];

  useEffect(() => {
    setColumnMappings(
      columns.map((columnName, index) => {
        let defaultMapping: ParticipantColumn | null = null;
        for (const property of Object.keys(defaultMappings)) {
          //@ts-ignore
          if (defaultMappings[property].find((term) => columnName.toLowerCase().includes(term.toLowerCase()))) {
            //@ts-ignore
            defaultMapping = ParticipantColumn[property];
          }
        }
        return {
          columnName,
          columnIndex: index,
          mapping: defaultMapping,
        };
      })
    );
  }, [data]);

  return (
    <div className="px-5 mt-10">
      <div className="text-lg text-gray-800">Column Mapping</div>
      <div className="text-sm text-gray-600">Match the columns from your spreadsheet to the corresponding fields</div>
      <div className="text-sm text-gray-400 italic">Some columns were mapped automatically, please verify they are correct</div>
      <ul className="divide-y divide-gray-100 mt-5">
        <li className="flex items-center justify-between gap-x-6 py-2">
          <div className="min-w-0 w-1/3">
            <p className="text-sm/6 font-semibold text-gray-900">Spreadsheet Column</p>
          </div>
          <div className="min-w-0 w-1/3">
            <p className="text-sm/6 font-semibold text-gray-900"></p>
          </div>
          <div className="min-w-0 w-1/3 text-right">
            <p className="text-sm/6 font-semibold text-gray-900">Strata Intel Column</p>
          </div>
        </li>
        {columns.map((columnName, index) => {
          const mapping = columnMappings.find((s) => s.columnName === columnName);

          return (
            <li key={index} className="flex items-center justify-between gap-x-6 py-2">
              <div className="min-w-0 w-1/3">
                <p className="text-sm/6 text-gray-600">{columnName}</p>
              </div>
              <div className="min-w-0 w-1/3">
                <p className="text-xs text-gray-500 italic flex justify-center items-center">
                  Maps to <ArrowRightIcon className="ml-1 size-4" />
                </p>
              </div>
              <div className="min-w-0 w-1/3 flex justify-end">
                <div className="flex items-end gap-x-3 w-1/2">
                  <select
                    value={!mapping || mapping.mapping === null ? "" : mapping.mapping}
                    onChange={(e) => {
                      const newSelections = structuredClone(columnMappings);
                      const selectionToUpdate = newSelections.find((s) => s.columnName === columnName)!;
                      // @ts-ignore
                      selectionToUpdate.mapping = e.target.value === "" ? null : ParticipantColumn[ParticipantColumn[e.target.value as keyof typeof ParticipantColumn]];
                      setColumnMappings(newSelections);
                    }}
                    className={`${!mapping || mapping.mapping === null ? "text-gray-400" : "text-gray-900"} col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6`}
                  >
                    <option className="text-gray-600" value={""}>
                      Not Mapped
                    </option>
                    {Object.keys(ParticipantColumn)
                      .filter((key) => isNaN(Number(key)))
                      .map((property) => (
                        <option key={property} value={ParticipantColumn[property as keyof typeof ParticipantColumn]}>
                          {/* @ts-ignore */}
                          {propertyNames[property]}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
