import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import AnnotationPlugin from "chartjs-plugin-annotation";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import colors from "tailwindcss/colors";
import CustomTooltip from "../components/Tooltip";
import RangeSelector from "./RangeSelector";
import "chartjs-adapter-date-fns";
import { subMonths, startOfMonth, isBefore, isSameMonth, format, startOfYear, min } from "date-fns";
import { enUS } from "date-fns/locale";
import { CalendarDateRangeIcon, PercentBadgeIcon, PlusIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import Toggle from "./Toggle";
import customIcons from "../assets/customIcons";
import { CompanyEvent } from "../types";
import CompanyEventModal from "./CompanyEventModal";
import Button from "./Button";
import { SI_VALUE_LOWER_CUTOFF, SI_VALUE_UPPER_CUTOFF } from "../constants";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, ChartDataLabels, AnnotationPlugin);

const generateMonthsRange = (startDate: Date, endDate: Date): Date[] => {
  let months: Date[] = [];
  let currentDate = startOfMonth(startDate);
  while (isBefore(currentDate, endDate) || isSameMonth(currentDate, endDate)) {
    months.push(currentDate);
    currentDate = subMonths(currentDate, -1);
  }
  return months;
};

type TrendChartData = {
  value: number | null;
  date: Date;
};

type Dataset = {
  label: string;
  data: TrendChartData[];
  color?: string;
};

const lineColors = [
  colors.blue[500],
  colors.purple[500],
  colors.orange[500],
  colors.gray[500],
  colors.pink[500],
  colors.emerald[500],
  colors.amber[500],
  colors.sky[500],
  colors.fuchsia[500],
  colors.rose[500],
];

export default function TrendChart(props: {
  title?: string;
  showTitle?: boolean;
  yAxisLabel?: string;
  yAxisUnits?: string;
  datasets: Dataset[];
  tooltip?: string;
  hideRangeSelector?: boolean;
  hideLegend?: boolean;
  suggestedMin?: number;
  suggestedMax?: number;
  stepSize?: number;
  className?: string;
  calcType?: "average" | "add";
  displayType?: "normal" | "percent";
  showDataLabel?: boolean;
  height?: string;
  showPercentageToggle?: boolean; 
  defaultShowAsPercentage?: boolean;
  companyEvents?: CompanyEvent[];
  printable?: boolean;
}) {
  const {
    datasets,
    title,
    yAxisLabel,
    yAxisUnits,
    tooltip,
    hideRangeSelector,
    hideLegend,
    suggestedMin,
    suggestedMax,
    stepSize,
    className,
    calcType,
    displayType,
    showTitle,
    showDataLabel,
    height,
    showPercentageToggle,
    defaultShowAsPercentage,
    companyEvents,
    printable,
  } = props;
  const [selectedDate, setSelectedDate] = useState<"6M" | "1Y" | "YTD" | "MAX">("6M");
  const [filteredData, setFilteredData] = useState<Array<{ label: string; data: { value: number | null; date: string }[]; color?: string }>>([]);
  const [showAsPercentage, setShowAsPercentage] = useState<boolean>(defaultShowAsPercentage ?? false);
  const [selectedCompanyEvent, setSelectedCompanyEvent] = useState<CompanyEvent>();
  const [showCompanyEventModal, setShowCompanyEventModal] = useState<boolean>(false);

  useEffect(() => {
    const now = new Date();
    let startDate: Date;

    switch (selectedDate) {
      case "6M":
        startDate = subMonths(now, 5);
        break;
      case "1Y":
        startDate = subMonths(now, 11);
        break;
      case "YTD":
        startDate = startOfYear(now);
        break;
      case "MAX":
      default:
        // Flatten the nested data arrays and filter out nulls
        const flattenedData = datasets.flatMap((obj) => obj.data).filter((item) => item !== null);

        // Find the object with the oldest date
        const oldestObject = flattenedData.reduce((oldest, current) => {
          return new Date(oldest.date) < new Date(current.date) ? oldest : current;
        });
        startDate = oldestObject.date;
        break;
    }

    const monthsRange = generateMonthsRange(startDate, now);

    // Step 1: Calculate the total sum for each month across all datasets (for percentage calculation)
    let totalMonthlySums: any = {};
    if (showAsPercentage) {
      monthsRange.forEach((month) => {
        let totalSum = 0;
        datasets.forEach((dataset) => {
          const dataPointsInMonth = dataset.data.filter((d) => d && isSameMonth(new Date(d.date), month));
          totalSum += dataPointsInMonth.reduce((sum, dp) => sum + (dp.value ?? 0), 0);
        });
        totalMonthlySums[format(month, "yyyy-MM")] = totalSum;
      });
    }

    // Step 2: Map over the datasets and calculate values
    const filtered = datasets.map((dataset, index) => ({
      label: dataset.label,
      data: monthsRange.map((month) => {
        const monthStr = format(month, "yyyy-MM");
        const dataPointsInMonth = dataset.data.filter((d) => d && isSameMonth(new Date(d.date), month));

        if (dataPointsInMonth.length === 0) {
          // No data points for this month
          return {
            date: monthStr,
            value: null,
          };
        }

        // Calculate sum of data points in the month
        let monthlySum = 0;
        dataPointsInMonth.forEach((dp) => {
          monthlySum += dp.value ?? 0;
        });

        // Step 3: Calculate the value based on calcType
        let value;
        if (showAsPercentage) {
          const totalSum = totalMonthlySums[monthStr] || 1; // Avoid division by zero
          value = monthlySum / totalSum;
        } else if (calcType === "add") {
          value = monthlySum;
        } else {
          value = monthlySum / dataPointsInMonth.length; // Average
        }

        return {
          date: monthStr,
          value: value,
        };
      }),
      color: dataset.color ?? lineColors[index],
    }));

    setFilteredData(filtered);
  }, [datasets, selectedDate, calcType, showAsPercentage]);

  return (
    <div className={`h-full w-full relative ${className ?? ""}`} style={height ? { height: height } : {}}>
      {!printable && (
        <div className="h-[10%]">
          <CompanyEventModal open={showCompanyEventModal} setOpen={setShowCompanyEventModal} companyEvent={selectedCompanyEvent} />
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <h3 className="text-sm font-medium text-gray-500 mr-2">{showTitle ? title : ""}</h3>
              {tooltip && <CustomTooltip message={tooltip} position="above" />}
            </div>
            <div className="">
              <div className="flex items-center gap-2">
                {showPercentageToggle && (
                  <div className="text-gray-500 hover:text-gray-700 cursor-pointer">
                    <Toggle
                      value={showAsPercentage}
                      setValue={(value) => (value ? setShowAsPercentage(true) : setShowAsPercentage(false))}
                      offIcon={customIcons.percentSign}
                      onIcon={customIcons.user}
                    />
                  </div>
                )}
                {!hideRangeSelector && <RangeSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate as (value: string) => void} />}
                {companyEvents && (
                  <Button
                    text="+"
                    icon={CalendarDateRangeIcon}
                    onClick={() => {
                      setSelectedCompanyEvent(undefined);
                      setShowCompanyEventModal(true);
                    }}
                    swapIconPosition={true}
                    className="hidden sm:flex"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={`${printable ? "h-full" : "h-[90%]"}`}>
        <Line
          options={{
            spanGaps: true,
            responsive: true,
            plugins: {
              legend: {
                position: "top" as const,
                display: !hideLegend,
              },
              datalabels: {
                backgroundColor: (context: any) => {
                  var index = context.dataIndex;
                  var value = context.dataset.data[index];

                  if (title === "SI Value") {
                    if (value < SI_VALUE_LOWER_CUTOFF) {
                      return colors.red[400];
                    } else if (value >= SI_VALUE_UPPER_CUTOFF) {
                      return colors.green[400];
                    } else {
                      return colors.yellow[400];
                    }
                  }

                  return context.dataset.backgroundColor;
                },
                borderRadius: 4,
                color: "white",
                font: {
                  weight: "bold",
                  size: 14,
                },
                formatter: Math.round,
                padding: 6,
                align: (ctx) => ((ctx.dataset.data[ctx.dataIndex] as number) > 90 ? "start" : "end"),
                anchor: (ctx) => ((ctx.dataset.data[ctx.dataIndex] as number) > 90 ? "start" : "end"),
                display: showDataLabel ? showDataLabel : false,
              },
              annotation: {
                annotations: companyEvents
                  ?.filter(
                    (ce) =>
                      new Date(ce.event_date) < new Date() &&
                      (selectedDate === "6M"
                        ? new Date(ce.event_date) > subMonths(new Date(), 6)
                        : selectedDate === "1Y"
                          ? new Date(ce.event_date) > subMonths(new Date(), 12)
                          : true)
                  ) // only show events that have passed
                  .sort((a, b) => new Date(a.event_date).getTime() - new Date(b.event_date).getTime())
                  .map((ce, index) => {
                    const formattedDate = new Date(ce.event_date).toISOString().split("T")[0]; // example: 2024-10-14
                    const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
                    return {
                      id: ce.company_event_id.toString(),
                      type: "line",
                      xMin: formattedDate,
                      xMax: formattedDate,
                      borderColor: colors.blue[300],
                      borderWidth: 2,
                      label: {
                        display: true,
                        backgroundColor: colors.blue[500],
                        content: printable ? ce.event_name : letters[index],
                        position: "end",
                        padding: 4,
                      },
                      click: (ctx) => {
                        setSelectedCompanyEvent(companyEvents.find((ce) => ctx.element.options.id && ce.company_event_id === parseInt(ctx.element.options.id)));
                        setShowCompanyEventModal(true);
                      },
                      enter: (ctx) => {
                        document.body.style.cursor = "pointer";
                      },
                      leave: (ctx) => {
                        document.body.style.cursor = "default";
                      },
                    };
                  }),
              },
            },
            scales: {
              y: {
                suggestedMin,
                min: 0,
                suggestedMax: showAsPercentage || displayType === "percent" ? 1 : suggestedMax,
                reverse: false,
                title: {
                  text: yAxisLabel ? yAxisLabel : yAxisUnits && `${showAsPercentage ? "Percent" : "Number"} of ${yAxisUnits}`,
                  display: true,
                },
                ticks: {
                  stepSize: showAsPercentage || displayType === "percent" ? 0.25 : stepSize ? stepSize : 1,
                  format: {
                    style: showAsPercentage || displayType === "percent" ? "percent" : undefined,
                  },
                  callback: function (value) {
                    if (showAsPercentage || displayType === "percent") {
                      return parseFloat(value as string) * 100 + "%"; // Round to integer and add the percentage symbol
                    } else {
                      return value;
                    }
                  },
                },
                grid: {
                  drawBorder: true,
                  color: (context) => {
                    if (context.tick.value === 0) {
                      return colors.gray[500]; // Color for the line at y = 0
                    }
                    return "rgba(0, 0, 0, 0.1)"; // Color for other grid lines
                  },
                },
              },
              x: {
                type: "time",
                time: {
                  unit: "month",
                  tooltipFormat: "MMMM",
                },
                adapters: {
                  date: {
                    locale: enUS,
                  },
                },
                grid: {
                  display: filteredData.length > 10 ? true : false,
                },
              },
            },
            maintainAspectRatio: false,
          }}
          data={{
            labels: filteredData.length > 0 ? filteredData[0].data.map((data) => data.date) : [],
            datasets: filteredData.map((dataset) => ({
              label: dataset.label,
              data: dataset.data.map((data) => data.value),

              borderColor: dataset.color, // Use the color defined in the dataset
              borderWidth: 2,
              pointStyle: "circle",
              pointRadius: 4,
              pointHoverRadius: 6,
              backgroundColor: dataset.color,
              fill: false,
              tension: 0.2,
            })),
          }}
        />
      </div>
    </div>
  );
}
