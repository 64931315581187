import { useContext, useEffect, useState } from "react";
import ScheduleComponent from "../../components/Schedule";
import Spinner from "../../components/Spinner";
import Button from "../../components/Button";
import { Schedule } from "../../types";
import useApi from "../../hooks/useApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CompanyContext } from "../../contexts/companyContext";
import { useNotifications } from "../../contexts/notificationContext";

export default function SchedulePage() {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [selectedWeek, setSelectedWeek] = useState<number>(1);
  const [selectedDay, setSelectedDay] = useState<number>(2);
  const [selectedHour, setSelectedHour] = useState<number>(10);
  const [selectedMinute, setSelectedMinute] = useState<number>(0);

  const { getApiData, postApiData, putApiData, deleteApiData } = useApi();
  const companyContext = useContext(CompanyContext);
  const queryClient = useQueryClient();
  const notifications = useNotifications();

  const { isPending: scheduleIsPending, data: schedule } = useQuery<Schedule>({
    queryKey: ["schedule", companyContext.companyId],
    queryFn: () => getApiData(`/company/schedule`),
    retry: false,
  });

  useEffect(() => {
    if (schedule) {
      setSelectedWeek(schedule.week_of_month);
      setSelectedDay(schedule.day_of_week);
      setSelectedHour(schedule.hour);
      setSelectedMinute(schedule.minute);
    } else {
      setSelectedWeek(1);
      setSelectedDay(2);
      setSelectedHour(10);
      setSelectedMinute(0);
    }
  }, [schedule]);

  async function updateSchedule() {
    setSubmitting(true);
    try {
      const { response, body } = await putApiData("/company/schedule", {
        week_of_month: selectedWeek,
        day_of_week: selectedDay,
        hour: selectedHour,
        minute: selectedMinute,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      if (response.status === 401) {
        if (body.name === "TokenExpiredError") {
          window.location.reload();
        }
      }
      if (response.status !== 200) {
        console.error(response);
        setSubmitting(false);
        return;
      }

      notifications.addNotification("Success!", "Schedule updated successfully", "success");
      setSubmitting(false);
    } catch (err) {
      console.error(err);
    }
  }

  async function createSchedule() {
    setSubmitting(true);
    try {
      const { response, body } = await postApiData("/company/schedule", {
        week_of_month: selectedWeek,
        day_of_week: selectedDay,
        hour: selectedHour,
        minute: selectedMinute,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      if (response.status === 401) {
        if (body.name === "TokenExpiredError") {
          window.location.reload();
        }
      }
      if (response.status !== 200) {
        console.error(response);
        setSubmitting(false);
        return;
      }

      queryClient.invalidateQueries({ queryKey: ["schedule", companyContext.companyId] });
      notifications.addNotification("Success!", "Schedule created successfully", "success");
      setSubmitting(false);
    } catch (err) {
      console.error(err);
    }
  }

  if (scheduleIsPending) {
    return <Spinner />;
  }
  return (
    <div>
      <div className="px-4 sm:px-0 pb-5 border-b ">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Assessment Schedule</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Choose when you would like the monthly well-being assessment to be sent</p>
      </div>
      <ScheduleComponent
        selectedWeek={selectedWeek}
        setSelectedWeek={setSelectedWeek}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        selectedHour={selectedHour}
        setSelectedHour={setSelectedHour}
        selectedMinute={selectedMinute}
        setSelectedMinute={setSelectedMinute}
      />
      <Button text={schedule ? "Update" : "Create"} className="mt-9" onClick={schedule ? updateSchedule : createSchedule} submitting={submitting} />
    </div>
  );
}
