import Papa from "papaparse";
import { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import UsersTable from "./UserTable";
import useApi from "../../hooks/useApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CompanyContext } from "../../contexts/companyContext";
import { ArrowUpOnSquareIcon, ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Participant, ParticipantColumn, ParticipantColumnMapping, ParticipantUpload } from "../../types";
import { UserPlusIcon } from "@heroicons/react/16/solid";
import UserUploadTable from "./UserUploadTable";
import { useNotifications } from "../../contexts/notificationContext";
import ColumnMapper from "./ColumnMapper";
import * as XLSX from "xlsx";

export default function UserUpload(props: { onUpload?: () => void }) {
  const [uploadedData, setUploadedData] = useState<string[][]>([]);
  const [uploadedUsers, setUploadedUsers] = useState<ParticipantUpload[]>([]);
  const [invalidRows, setInvalidRows] = useState<{ row: number; error: string }[]>([]);
  const [warningRows, setWarningRows] = useState<{ row: number; warning: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [columnMappings, setColumnMappings] = useState<ParticipantColumnMapping[]>([]);

  const { postApiData } = useApi();
  const queryClient = useQueryClient();
  const companyContext = useContext(CompanyContext);
  const notifications = useNotifications();
  const { getApiData } = useApi();

  const { data: participants } = useQuery<Participant[]>({
    queryKey: ["participants", companyContext.companyId],
    queryFn: async () => getApiData(`/company/users`),
    initialData: [],
  });

  async function uploadUsers() {
    const emailMapping = columnMappings.find((mapping) => mapping.mapping === ParticipantColumn.email);
    const phoneMapping = columnMappings.find((mapping) => mapping.mapping === ParticipantColumn.phone_number);

    if (!emailMapping?.mapping) {
      notifications.addNotification("Error", "The Email column has not been mapped, please select the email column from your spreadsheet", "error");
      return;
    }

    if (!phoneMapping?.mapping) {
      notifications.addNotification("Error", "The Phone Number column has not been mapped, please select the phone number column from your spreadsheet", "error");
      return;
    }

    try {
      setLoading(true);

      const { response } = await postApiData("/company/users/bulk", uploadedUsers);

      if (response.ok) {
        queryClient.invalidateQueries({ queryKey: ["participants", companyContext.companyId] });
        queryClient.invalidateQueries({ queryKey: ["groups", companyContext.companyId] });

        setUploadedUsers([]);
        setLoading(false);
        if (props.onUpload) {
          props.onUpload();
        }
      } else {
        console.error(response);
        notifications.addNotification("Error", "An error occurred while updating participants, please try again later", "error");
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    const usersPreview: ParticipantUpload[] = [];
    for (const mapping of columnMappings) {
      if (mapping.mapping !== null) {
        for (let i = 0; i < uploadedData.length; i++) {
          if (!usersPreview[i]) {
            usersPreview[i] = {
              first_name: null,
              last_name: null,
              email: null,
              phone_number: null,
              job_title: null,
              group_names: [],
            };
          }

          if (mapping.mapping === ParticipantColumn.group_names) {
            // if there are multiple group columns mapped, append each one
            if (uploadedData[i][mapping.columnIndex]) {
              usersPreview[i].group_names.push(uploadedData[i][mapping.columnIndex]);
            }
          } else if (mapping.mapping === ParticipantColumn.phone_number) {
            // if there are multiple phone numbers in a cell, take the first - also strip non-digits
            const phoneMatch = uploadedData[i][mapping.columnIndex]?.match(/\b(\+?\d[\d\s\-().]*)\b/);
            usersPreview[i].phone_number = phoneMatch ? phoneMatch[0].trim() : "";
          } else {
            // match the rest of the columns
            //@ts-ignore
            usersPreview[i][ParticipantColumn[mapping.mapping]] = uploadedData[i][mapping.columnIndex]?.trim();
          }
        }
      }
    }
    usersPreview.shift(); // remove first row (column headers)
    setUploadedUsers(usersPreview);
  }, [columnMappings, uploadedData]);

  function handleFile(file: File) {
    if (file.type === "text/csv") {
      Papa.parse(file, {
        complete: function (results: any) {
          setUploadedData(results.data);
        },
      });
    } else if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel") {
      // .xlsx or xls
      const reader = new FileReader();

      // Read the file as a binary string
      reader.onload = (e) => {
        const arrayBuffer = e.target?.result;

        const workbook = XLSX.read(arrayBuffer, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Convert sheet data to JSON
        const jsonData: (string | number | boolean | null)[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Convert all cells to strings
        const stringArray = jsonData.map((row) => row.map((cell) => (cell !== null && cell !== undefined ? String(cell) : "")));

        setUploadedData(stringArray);
      };

      // Read the file
      reader.readAsArrayBuffer(file);
    } else {
      alert("Unrecognized file type, please select either a .csv or .xlsx file");
    }
  }

  return (
    <div className="py-5 sm:p-0">
      {uploadedUsers.length <= 0 && (
        <div className="pb-6 sm:grid sm:grid-cols-3 sm:gap-4">
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
            <div
              onDrop={(e) => {
                e.preventDefault();
                const file = e.dataTransfer.files?.[0];
                if (file) handleFile(file);
              }}
              onDragOver={(e) => e.preventDefault()}
              className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
            >
              <div className="text-center">
                <ArrowUpOnSquareIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-300" />
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-strataBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-strataBlue focus-within:ring-offset-2 hover:text-blue-900"
                  >
                    <span>Choose a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      accept=".csv,.xlsx,.xls"
                      onChange={(e) => {
                        let files = e.target.files;
                        if (files) {
                          const file = files[0];
                          handleFile(file);
                        }
                      }}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs leading-5 text-gray-600">CSV, XLS, XLSX</p>
              </div>
            </div>
          </dd>
        </div>
      )}

      {uploadedData.length > 0 && (
        <div>
          <ColumnMapper data={uploadedData} columnMappings={columnMappings} setColumnMappings={setColumnMappings} />
          <div className="mt-10">
            <UserUploadTable users={uploadedUsers} limit={5} />
          </div>
          {invalidRows.length > 0 && (
            <div className="rounded-md bg-yellow-50 p-4 mb-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">The following participants were not imported:</h3>
                </div>
              </div>
              <div className="mt-2 text-sm text-yellow-700 ml-1">
                <ul>
                  {invalidRows.map((row) => (
                    <li key={row.row}>
                      <span className="font-medium">Row {row.row}</span>: {row.error}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {warningRows.length > 0 && (
            <div className="rounded-md bg-yellow-50 p-4 mb-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">The following lines had issues but were still imported:</h3>
                </div>
              </div>
              <div className="mt-2 text-sm text-yellow-700 ml-1">
                <ul>
                  {warningRows.map((row) => (
                    <li key={row.row}>
                      <span className="font-medium">Row {row.row}</span>: {row.warning}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <div className="flex justify-between py-4 sm:py-5">
            <Button
              text="Clear"
              icon={XMarkIcon}
              variant="secondary"
              onClick={() => {
                setUploadedUsers([]);
                setInvalidRows([]);
                setWarningRows([]);
              }}
            />
            <Button
              text={participants.length > 0 ? "Update Participants" : "Import Participants"}
              icon={UserPlusIcon}
              submitting={loading}
              onClick={() => uploadUsers()}
            />
          </div>
        </div>
      )}
    </div>
  );
}
