import parser from "cron-parser";
import { CompanyHealth, Participant, Respondent, Schedule, Survey } from "../types";
import colors from "tailwindcss/colors";
import { DISENGAGED_UPPER_CUTOFF, HIGH_GREEN_UPPER_CUTOFF, HIGH_YELLOW_UPPER_CUTOFF, LOW_YELLOW_UPPER_CUTOFF, MID_GREEN_UPPER_CUTOFF, MID_YELLOW_UPPER_CUTOFF, SI_VALUE_LOWER_CUTOFF, SI_VALUE_UPPER_CUTOFF } from "../constants";

export function formatPhoneNumber(phoneNumberString: string) {
  // Remove all non-digit characters
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");

  // Remove leading '1' if present (for US country code)
  if (cleaned.length === 11 && cleaned[0] === "1") {
    cleaned = cleaned.slice(1);
  }

  // Match the cleaned string to a 10-digit phone number pattern
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  // Return the original input if the formatting does not apply
  return phoneNumberString;
}

export function stripNonNumbers(text: string) {
  return text.replace(/\D/g, "");
}

// Function to calculate the sum of an array of numbers
export function calculateSum(arr: Array<number>): number {
  if (arr.length === 0) return 0;
  const sum = arr.reduce((acc, val) => acc + val, 0);
  return sum;
}

// Function to calculate the average of an array of numbers
export function calculateAverage(arr: Array<number>): number {
  if (arr.length === 0) return 0;
  const sum = arr.reduce((acc, val) => acc + val, 0);
  return sum / arr.length;
}

export function calculateLowerQuartile(numbers: Array<number>): number {
  // Step 1: Sort the array
  numbers.sort((a, b) => a - b);

  // Step 2: Calculate the median
  const median = calculateMedian(numbers);

  // Step 3: Exclude the median from the lower quartile calculation
  const lowerHalf = numbers.filter((num) => num < median);

  // Step 4: Calculate the median of the lower half
  const lowerQuartile = calculateMedian(lowerHalf);

  return lowerQuartile;
}

export function calculateUpperQuartile(numbers: Array<number>): number {
  // Step 1: Sort the array
  numbers.sort((a, b) => a - b);

  // Step 2: Calculate the median
  const median = calculateMedian(numbers);

  // Step 3: Exclude the median from the upper quartile calculation
  const upperHalf = numbers.filter((num) => num > median);

  // Step 4: Calculate the median of the upper half
  const upperQuartile = calculateMedian(upperHalf);

  return upperQuartile;
}

export function calculateMedian(numbers: Array<number>): number {
  const length = numbers.length;
  const middle = Math.floor(length / 2);
  if (length % 2 === 0) {
    // If the length is even, return the average of the two middle values
    return (numbers[middle - 1] + numbers[middle]) / 2;
  } else {
    // If the length is odd, return the middle value
    return numbers[middle];
  }
}

export function percentageChange(newValue: number, oldValue: number): number {
  let change = ((newValue - oldValue) / oldValue) * 100;
  return Math.round(change);
}

export function getUniqueItems(array: Array<number | string>): Array<number | string> {
  return Array.from(new Set(array));
}

export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function getNextScheduledDate(schedule: Schedule): Date {
  const cronExpression = `${schedule.minute} ${schedule.hour} * * ${schedule.day_of_week}#${schedule.week_of_month + 1}`;
  const interval = parser.parseExpression(cronExpression, {
    currentDate: new Date(),
    tz: schedule.timezone,
  });
  const scheduledDateForMonth = interval.next().toDate();

  return scheduledDateForMonth;
}

export function displayMonth(monthIndex: number): string {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return monthNames[monthIndex];
}

export function getLatestCompletedSurvey(surveys: Survey[]): Survey | null {
  return surveys.filter((s) => new Date(s.end_date) < new Date() && s.si_value).sort((a, b) => new Date(b.end_date).getTime() - new Date(a.end_date).getTime())[0];
}

export function getPreviousCompletedSurvey(surveys: Survey[]): Survey | null {
  return surveys.filter((s) => new Date(s.end_date) < new Date() && s.si_value).sort((a, b) => new Date(b.end_date).getTime() - new Date(a.end_date).getTime())[1];
}

export function getCurrentSurvey(surveys: Survey[]): Survey | null {
  return surveys.find((s) => new Date(s.start_date) < new Date() && new Date(s.end_date) > new Date()) ?? null;
}

export function filterImpairedRespondents(respondents: Respondent[]): Respondent[] {
  if (respondents && respondents.length > 0) {
    return respondents.filter((res) => parseFloat(res.mental_health_value) >= HIGH_GREEN_UPPER_CUTOFF);
  }
  return [];
}

export function getRemovedParticipantCountByMonth(companyHealth: CompanyHealth[], month: number): number {
  const companyHealthForMonth = companyHealth.filter((ch) => new Date(ch.created_at).getMonth() === month);
  let total = 0;
  companyHealthForMonth.forEach((ch) => {
    total += ch.terminated_employees;
  });
  return total;
}

export function getAddedParticipantCountByMonth(companyHealth: CompanyHealth[], month: number): number {
  const companyHealthForMonth = companyHealth.filter((ch) => new Date(ch.created_at).getMonth() === month);
  let total = 0;
  companyHealthForMonth.forEach((ch) => {
    total += ch.new_employees;
  });
  return total;
}

export function calculatePercentageChange(oldValue: number, newValue: number): number {
  if (!oldValue && !newValue) {
    return 0;
  }
  if (oldValue === 0) {
    return newValue * 100;
  }
  return Math.round(((newValue - oldValue) / oldValue) * 100);
}

export function calculateStressCategoryCounts(respondents: Respondent[]): {
  disengaged: number;
  red: number;
  yellow: number;
  green: number;
  midGreen: number;
  highGreen: number;
  lowYellow: number;
  midYellow: number;
  highYellow: number;
} {
  let counts = { disengaged: 0, red: 0, yellow: 0, green: 0, midGreen: 0, highGreen: 0, lowYellow: 0, midYellow: 0, highYellow: 0 };

  respondents.forEach((respondent) => {
    const mentalHealthValue = parseFloat(respondent.mental_health_value);
    if (mentalHealthValue <= DISENGAGED_UPPER_CUTOFF) counts.disengaged++;
    else if (mentalHealthValue <= MID_GREEN_UPPER_CUTOFF) counts.midGreen++;
    else if (mentalHealthValue <= HIGH_GREEN_UPPER_CUTOFF) counts.highGreen++;
    else if (mentalHealthValue <= LOW_YELLOW_UPPER_CUTOFF) counts.lowYellow++;
    else if (mentalHealthValue <= MID_YELLOW_UPPER_CUTOFF) counts.midYellow++;
    else if (mentalHealthValue <= HIGH_YELLOW_UPPER_CUTOFF) counts.highYellow++;
    else counts.red++;
  });

  counts.yellow = counts.highYellow + counts.midYellow + counts.lowYellow;
  counts.green = counts.highGreen + counts.midGreen;

  return counts;
}

export function getInitialsFromName(name: string): string {
  return name.split(" ").length > 1 ? name.trim().split(" ")[0][0] + name.trim().split(" ").slice(-1)[0][0] : name.trim().split(" ")[0][0];
}

export function getSIValueTextColorClassName(siValue: string | number): string {
  if(typeof siValue === "string") {
    siValue = parseFloat(siValue);
  }
  
  if (siValue >= SI_VALUE_UPPER_CUTOFF) {
    return "text-green-500";
  } else if (siValue >= SI_VALUE_LOWER_CUTOFF) {
    return "text-yellow-500";
  } else {
    return "text-red-500";
  }
}
